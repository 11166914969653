<template>
  <el-form
    ref="jsonValidateForm"
    class="json-editor h-full w-full"
    :model="form"
    :rules="rules"
  >
    <el-form-item prop="currentValue" class="h-full w-full">
      <div class="w-full justify-end flex">
        <el-button
          v-if="showClose"
          class="text-white"
          icon="Close"
          text
          @click="$emit('close-json-editor')"
        />
      </div>
      <monaco-editor
        id="monacoEditor"
        ref="editor"
        :value="form.currentValue"
        class="h-full w-full"
        :options="editorOptions"
        theme="vs-dark"
        language="json"
        handle-resize
        @change="jsonChange"
      />
    </el-form-item>
  </el-form>
</template>

<script>
import MonacoEditor from 'monaco-editor-vue3'

export default {
  components: {
    MonacoEditor
  },
  props: {
    jsonInput: {
      type: Object,
      default: null
    },
    showClose: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    let validateJson = (rule, value, callback) => {
      if (this.validateJson()) {
        callback()
      } else {
        callback(new Error('Invalid JSON'))
      }
    }
    return {
      form: {
        currentValue: ''
      },
      rules: {
        currentValue: [{ validator: validateJson, trigger: 'blur' }]
      },
      editorOptions: {
        readOnly: this.readonly,
        lineNumbers: true,
        language: 'json',
        tabSize: 2
      }
    }
  },
  computed: {
    isNew() {
      if (this.$route.params.id && this.$route.params.id === 'new') return true

      return !this.$route.params.id
    },
    jsonPayload() {
      try {
        return this.jsonInput
          ? JSON.stringify(this.jsonInput, null, 2) + '\n'
          : ''
      } catch (err) {
        return this.jsonInput
      }
    }
  },
  watch: {
    jsonPayload: {
      immediate: true,
      handler: 'setCurrentValue'
    }
  },
  methods: {
    jsonChange(value) {
      this.setCurrentValue(value)
      this.$refs['jsonValidateForm'].validate((valid) => {
        if (valid) {
          this.$emit('jsonChanged', this.form.currentValue) //return stringify value
          this.$emit('jsonParsed', this.parseJSON(this.form.currentValue)) //return JSON object
        }
      })
    },
    setCurrentValue(value) {
      this.form.currentValue = value
    },
    validateJson() {
      let valid = true
      try {
        JSON.parse(this.form.currentValue)
      } catch (err) {
        valid = false
      }
      return this.form.currentValue.replace(/\s/g, '') === '' || valid
    },
    resetData() {
      this.setCurrentValue(this.isNew ? '' : this.jsonPayload)
      this.$refs['jsonValidateForm'].validate()
    },
    parseJSON(value) {
      try {
        return value.replace(/\s/g, '') !== '' ? JSON.parse(value) : {}
      } catch (err) {
        this.$logException(err)
      }
    }
  }
}
</script>

<style scoped>
.json-editor :deep(.el-form-item__content) {
  height: 100%;
  width: 100%;
}

.json-editor :deep(.view-lines *) {
  font-family: Menlo, Monaco, 'Courier New', monospace !important;
}
</style>
